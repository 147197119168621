import { createEffect, createEvent, restore, type EventPayload } from 'effector';
import { type NextRouter } from 'next/router';
import {
	AppointmentsGetLocaleEnum,
	type AppointmentsAppointmentIdGetLocaleEnum,
} from '../../services/midtier/booking-management/appointment-history/__generated';

type PushParameters = Parameters<NextRouter['push']>;

export type PushPayload = {
	url: PushParameters[0];
	as?: PushParameters[1];
	options?: PushParameters[2];
};

export const push = createEvent<PushPayload>();

export const setRoute = createEvent<string>();
export const setLocale = createEvent<string>();
export const setLangCode = createEvent<string>();
export const setLocaleCode = createEvent<AppointmentsAppointmentIdGetLocaleEnum>();
export const setIsFrench = createEvent<boolean>();

export const $route = restore(setRoute, '');
export const $locale = restore(setLocale, 'en');
export const $langCode = restore(setLangCode, 'eng');
export const $localeCode = restore(setLocaleCode, AppointmentsGetLocaleEnum.EnCa);
export const $isFrench = restore(setIsFrench, false);

export const changeLocationFx = createEffect(({ url }: PushPayload) => {
	if (typeof window !== 'undefined') {
		window.location.href = url.toString();
	}
});

export const pushPayload = (url: PushParameters[0], as?: PushParameters[1], options?: PushParameters[2]) =>
	({ url, as, options }) satisfies EventPayload<typeof push>;

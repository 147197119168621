import { useEnvironment } from '@/shared/environment';
import { useLanguage } from '@/shared/useLanguage';
import Head from 'next/head';
import type { FunctionComponent, ReactNode } from 'react';
import type { Maybe, SeoSettingsFieldsFragment } from '../../contentful';
import { defaultSeoValues } from './default-seo-values';
import type { LinkTag, OpenGraph, SeoSettings, Twitter } from './types';

const openGraphRender = (props: OpenGraph, canonicalUrl: string) => {
	const assets: ReactNode[] = [];
	if (props.images) {
		const { asset, altText } = props.images;
		if (asset.url && asset.mimeType && altText) {
			assets.push(
				<meta key="og:image" property="og:image" content={asset.url} />,
				<meta key="og:type" property="og:type" content={asset.mimeType} />,
				<meta key="og:image:alt" property="og:image:alt" content={altText} />
			);
		}
	}

	return (
		<>
			{(props.url || canonicalUrl) && <meta key="og:url" property="og:url" content={props.url ?? canonicalUrl} />}
			{props.type && <meta key="og:type" property="og:type" content={props.type.toLowerCase()} />}
			{assets}
		</>
	);
};

const twitterTagRender = (props: Twitter) => {
	return (
		<>
			{props.cardType && <meta key="twitter:card" name="twitter:card" content={props.cardType} />}
			{props.site && <meta key="twitter:site" name="twitter:site" content={props.site} />}
		</>
	);
};

const additionalLinkTagRender = (props: LinkTag[]) => {
	return props.map((tag) => {
		const { crossOrigin: tagCrossOrigin, ...rest } = tag;
		const crossOrigin =
			tagCrossOrigin === 'anonymous' || tagCrossOrigin === 'use-credentials' || tagCrossOrigin === ''
				? tagCrossOrigin
				: undefined;
		return <link key={`link${rest.keyOverride ?? rest.href}${rest.rel}`} {...rest} crossOrigin={crossOrigin} />;
	});
};

export type SEOProps = {
	settings?: Maybe<SeoSettingsFieldsFragment>;
};

export const SEO: FunctionComponent<SEOProps> = ({ settings }) => {
	const { locale } = useLanguage();
	const data = settings ? deriveSeoPropsFromData(settings) : defaultSeoValues[locale];
	const { isProductionDomain } = useEnvironment();
	const { title, description, canonicalUrl, robots, openGraph, twitter, additionalMetaTags, additionalLinkTags } = data;
	return (
		<Head>
			{title && <title key="title">{title}</title>}
			{description && <meta key="description" name="description" content={description} />}
			{canonicalUrl && <link rel="canonical" href={canonicalUrl} key="canonical" />}
			{
				<meta
					key="robots"
					name="robots"
					content={`${robots.metaRobotsIndex && isProductionDomain ? 'index' : 'noindex'},${
						robots.crawlerFollow ? 'follow' : 'nofollow'
					}`}
				/>
			}
			{(openGraph?.title || title) && <meta key="og:title" property="og:title" content={openGraph?.title ?? title} />}
			{<meta key="og:description" property="og:description" content={openGraph?.description ?? description} />}
			{openGraph && openGraphRender(openGraph, canonicalUrl)}
			{twitter && twitterTagRender(twitter)}
			{additionalMetaTags?.length &&
				additionalMetaTags.map((tag) => <meta key={`meta:${tag.name ?? tag.property ?? tag.httpEquiv}`} {...tag} />)}
			{additionalLinkTags?.length && additionalLinkTagRender(additionalLinkTags as LinkTag[])}
		</Head>
	);
};

function valueOrDefault<T>(value: T | undefined, defaultValue: NonNullable<T>) {
	return value ?? defaultValue;
}

export function deriveSeoPropsFromData(tags?: Maybe<SeoSettingsFieldsFragment>): SeoSettings {
	return {
		title: valueOrDefault(tags?.title, ''),
		description: valueOrDefault(tags?.description, ''),
		canonicalUrl: valueOrDefault(tags?.canonicalUrl, ''),
		robots: {
			metaRobotsIndex: valueOrDefault(tags?.metaRobotsIndex, false),
			crawlerFollow: valueOrDefault(tags?.crawlerFollow, false),
		},
		additionalMetaTags: valueOrDefault(tags?.additionalMetaTags, []),
		additionalLinkTags: valueOrDefault(tags?.additionalLinkTags, []),
		openGraph: {
			title: valueOrDefault(tags?.openGraphTwitterTitle, ''),
			description: valueOrDefault(tags?.openGraphTwitterDescription, ''),
			type: valueOrDefault(tags?.openGraphType, ''),
			url: valueOrDefault(tags?.openGraphTwitterUrl, ''),
			images: {
				altText: valueOrDefault(tags?.openGraphTwitterImageReference?.altText, ''),
				asset: {
					url: valueOrDefault(tags?.openGraphTwitterImageReference?.asset?.url, null),
					mimeType: valueOrDefault(tags?.openGraphTwitterImageReference?.asset?.mimeType, null),
				},
			},
		},
		twitter: {
			cardType: valueOrDefault(tags?.twitterCard, ''),
			site: valueOrDefault(tags?.twitterSite, ''),
		},
	};
}

import type { Locale } from '@/shared/useLanguage';
import type { DropDownShape } from '../../public/locales/types/createProfile';

/**
 * Description:
 * Formats phone number
 * Max 10 chars
 * eg. xxx-xxx-xxxx
 */
export const phoneFormatAsString = (input: string) => {
	if (input.length === 10) {
		return input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
	}
	return input;
};

/**
 * Description:
 * Takes dash separated date as YYYY-MM-DD and returns slash separated
 * eg. DD / MM / YYYY
 */
export const dateFormatAsString = (input: string) => {
	if (!input) return '';
	const [year, month, day] = input.split('-');

	return `${day} / ${month} / ${year}`;
};

export const getAddressText = (street1: string, street2: string, city: string, province: string, postalCode: string) =>
	`${street1} ${street2} ${city} ${province} ${postalCode} CA`;

/**
 * Description:
 * This utility method was created to pass SonarQube test coverage
 */
export const getOptionalProp = (object: any, propName: string, fallback: any) => {
	if (propName in object) return object[propName];
	return fallback;
};

/**
 * Description:
 * Takes a comma delimited list, checks for 'Custom Option' and replaces it with a new value
 * eg. converts 'male, female, intersex, Custom Option', to 'male, female, intersex, Your Custom Option'
 */
export const replaceCustomOption = (options = '', oldCustomOption = '', newCustomOption = '') =>
	options.includes(oldCustomOption) ? options.replace(oldCustomOption, newCustomOption) : options;

/**
 * Description:
 * Formats value for Province dropdown
 */

export const findFullProvince = (provinceLabel: DropDownShape, provinceValue: string) => {
	if (!provinceValue) return '';
	const provKey = provinceLabel.options.find((option: { key: string }) => option.key === provinceValue);
	return provKey ? provKey.value : '';
};

/**
 * Description
 * Produces Date object based on YYYY-MM-DD string
 */
export const parseDateString = (dateString: string): Date => {
	const yearMonthDate = dateString.split('-');
	return new Date(parseInt(yearMonthDate[0]), parseInt(yearMonthDate[1]) - 1, parseInt(yearMonthDate[2]));
};

/**
 * Customized month abbreviation formatter
 * @param date
 * @param locale
 * @returns
 */
const monthAbbreviationFormatter = (date: string, locale: Locale) => {
	let result = date;
	if (locale === 'en') {
		result = result.replace('Sep', 'Sept');
	}
	if (locale === 'fr') {
		['janv', 'févr', 'mars'].forEach((abbrev) => {
			if (result.includes(abbrev)) {
				result = result.replace(abbrev, abbrev.slice(0, 3));
			}
		});
	}
	return result.replace('.', '');
};

/**
 * Format date for the appointments page
 * @param date
 * @param locale
 * @returns
 */
export const formatDateForAppointments = (date: Date, locale: Locale): string => {
	const isFrench = locale === 'fr';
	const dateOptions: Intl.DateTimeFormatOptions = {
		weekday: 'long',
		month: 'short',
		day: 'numeric',
	};
	const timeOptions: Intl.DateTimeFormatOptions = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: !isFrench,
		timeZoneName: isFrench ? undefined : 'short',
	};
	const frFormattedTime = date.toLocaleTimeString(locale, timeOptions).replace(':', ' h ');
	const formattedDate = date.toLocaleDateString(locale, dateOptions);
	const formattedTime = isFrench ? frFormattedTime : date.toLocaleTimeString(locale, timeOptions);
	return `${monthAbbreviationFormatter(formattedDate, locale)}, ${formattedTime}`;
};

/**
 * Description:
 * Formats number for en / fr in Canada
 * EN: 1,000
 * FR: 1 000
 */

export const formatNumberEnFr = (value = 0, locale = 'en') => {
	return Intl.NumberFormat(locale).format(value);
};

/**
 * Description:
 * Formats currency for en / fr in Canada
 * EN: $1,000
 * FR: 1 000 $
 * Accepts number of decimals: maximumFractionDigits
 * Accepts convertDollarToCents which will divide dollar by 100
 */

export const formatCurrencyEnFr = (
	value = 0,
	locale = 'en',
	maximumFractionDigits = 0,
	convertDollarToCents = true
) => {
	return Intl.NumberFormat(`${locale}-CA`, {
		style: 'currency',
		currency: 'CAD',
		maximumFractionDigits,
	}).format(convertDollarToCents ? value / 100 : value);
};

// SSR
export const isFrench = (locale: string) => locale === 'fr';

/**
 * Description:
 * Handy function for handling when a querystring returns it could be a string, an array of strings or undefined
 * Will return result based on the type of the querystring
 */

export const getQueryString = (querystring: string | string[] | undefined) => {
	return Array.isArray(querystring) ? querystring[0] : querystring || '';
};

export const longDateFormat = (dateString: Date, locale: string): string => {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	};

	return dateString.toLocaleDateString(`${locale}-CA`, options);
};

export const utcConvertedDateTime = (dateString: string): Date => {
	const date = dateString?.split('T')[0];
	const time = dateString?.split('T')[1]?.split('+')[0];
	const formattedDate = new Date(`${date} ${time} UTC`);
	return formattedDate;
};

export const longFormattedDate = (dateString: string, locale: string): string => {
	return longDateFormat(utcConvertedDateTime(dateString), locale);
};

export function isDateToday(input: Date | string) {
	return new Date(input).setHours(0, 0, 0, 0) == new Date(Date.now()).setHours(0, 0, 0, 0);
}

export const formatPhone = (phone: string) => {
	if (phone?.length >= 6) {
		return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}`;
	}
	return '';
};

export const formatEmail = (email: string) => {
	if (!email?.includes('@')) return '';
	const emailParts = email.split('@');
	const firstPart = emailParts[0];
	const secondPart = emailParts[1];
	return `${firstPart.at(0)}${'*'.repeat(firstPart.length - 2)}${firstPart.at(-1)}@${secondPart}`;
};

import { useLanguage } from '@/shared/useLanguage';
import { useRouter } from 'next/router';
import { useEffect, type FunctionComponent } from 'react';
import { push, setIsFrench, setLangCode, setLocale, setLocaleCode, setRoute } from './model';

export const RouterBridge: FunctionComponent = () => {
	const router = useRouter();

	const { locale, langCode, localeCode, isFrench } = useLanguage();

	useEffect(() => {
		const subscription = push.watch(({ url, as, options }) => router.push(url, as, options));

		return () => {
			subscription.unsubscribe();
		};
	}, [router]);

	useEffect(() => {
		setRoute(router.asPath);
	}, [router.asPath]);

	// TODO: PHARM-23788 move logic from useLanguage to effector

	useEffect(() => {
		setLocale(locale);
		setLangCode(langCode);
		setLocaleCode(localeCode);
		setIsFrench(isFrench);
	}, [locale, langCode, localeCode, isFrench]);

	return <></>;
};
